import { MetaSwiper } from "./meta-settings.js";
import { CountUp } from "./plugins/countUp.min.js";
document.addEventListener("DOMContentLoaded", function () {
	function hiddenCheckBox() {
		const selectWrap = document.querySelector('.js-select');
		const hiddenCheckBox = document.querySelector('.checkBox--hidden');

		selectWrap.addEventListener('change', () => {
			if (selectWrap.value === 'Work at FMC') {
				hiddenCheckBox.classList.remove('!hidden');
			} else {
				if (!hiddenCheckBox.classList.contains('!hidden')) {
					hiddenCheckBox.classList.add('!hidden');
				}
			}
		});
	}
	window.hiddenCheckBox = hiddenCheckBox;

	if (document.querySelector('.js-select')) {
		const selects = document.querySelectorAll('.js-select');

		selects.forEach((select, i) => {
			new Choices(select, {
				allowHTML: true,
				searchEnabled: !select.classList.contains('no-search'),
				placeholder: true,
				selected: true,
			});
		});
	}

	if (document.querySelector(".choices")) {
		const choicesWrappers = document.querySelectorAll(".choices");
		choicesWrappers.forEach(choiceWrap => {
			choiceWrap.addEventListener("click", () => {
				if (!choiceWrap.querySelector(".custom-simplebar-wrapper")) {
					const selectSimplebar = choiceWrap.querySelector(
						".choices__list--dropdown .choices__list"
					);
					const div = document.createElement("div");
					div.classList.add("custom-simplebar-wrapper");
					const content = selectSimplebar.innerHTML;
					selectSimplebar.innerHTML = "";
					div.setAttribute("data-simplebar", "");
					div.innerHTML = content;
					selectSimplebar.appendChild(div);
				}
			});
		});
	}

	// countUp
	let countUpArr = new Array();

	function CountUpFunction(id, finalNum, option = {}) {
		let defaultOptions = {
			separator: "",
			enableScrollSpy: true,
			scrollSpyRunOnce: true,
		};

		let mergeOptions = { ...defaultOptions, ...option };
		countUpArr.push(new CountUp(id, finalNum, mergeOptions));
	}

	if (document.querySelector(".discount__num")) {
		const counters = document.querySelectorAll(".discount__num");
		counter(counters)
	}
	if (document.querySelector(".find__month")) {
		const counters = document.querySelectorAll(".find__month span");
		counter(counters)
	}

	function counter(elements) {
		elements.forEach((counter, i) => {
			const value = counter.getAttribute("data-count");
			CountUpFunction(counter, value, { suffix: "" });
		});
	}
	// end countUp

	AOS.init({
		startEvent: 'load',
		offset: 150,
		delay: 0,
		duration: 500,
		easing: "ease",
		once: true,
	});

	// fix ios height
	if (innerWidth < 1024) {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
		window.addEventListener("resize", () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		});
	}
	// end fix ios height

	// burger-menu
	const burgerMenu = (menuSelector, burgerSelector) => {
		const menuElem = document.querySelector(menuSelector),
			layoutElem = document.querySelector(".header__layout"),
			burgerElem = document.querySelector(burgerSelector);

		const removeClass = () => {
			menuElem.classList.remove("active");
		};
		removeClass();

		menuElem.querySelectorAll("a").forEach(i => {
			i.addEventListener("click", e => {
				if (menuElem.classList.contains("active")) {
					removeClass();
				}
			});
		});

		burgerElem.addEventListener("click", () => {
			if (menuElem.classList.contains("active")) {
				removeClass();
			} else {
				menuElem.classList.add("active");
			}
		});

		window.addEventListener("resize", () => {
			if (innerWidth > 1023) {
				removeClass();
			}
		});

		layoutElem.addEventListener("click", () => {
			removeClass();
		});
	};

	if (document.querySelector(".header")) {
		burgerMenu(".header", ".header__burger");
	}
	// end burger-menu

	if (document.querySelector(".header__accordion")) {
		const accordions = document.querySelectorAll(".header__accordion");

		accordions.forEach(accordion => {
			const intro = accordion.querySelector(".header__link");

			intro.addEventListener("click", () => {
				accordion.classList.toggle("active");
			});
		});
	}

	function sectorDropMenu () {
		const dropBtn = document.querySelector("#sector_btn"),
			layoutElem = document.querySelector(".header__layout"),
			dropWrap = document.querySelector(".header__dropdown");

		layoutElem.addEventListener("click", e => {
			dropWrap.classList.remove("active");
			dropBtn.classList.remove("active");
		});

		dropBtn.addEventListener("click", e => {
			e.preventDefault();
			dropWrap.classList.toggle("active");
			dropBtn.classList.toggle("active");
		});

		window.addEventListener("resize", () => {
			if (innerWidth < 1024) {
				dropWrap.classList.remove("active");
				dropBtn.classList.remove("active");
			}
		});
	}

	if (innerWidth > 1023 && document.querySelector("#sector_btn")) {
		sectorDropMenu();
	}
	// sticky

	function stickyMenu () {
		const headerElem = document.querySelector(".header");

		if (window.pageYOffset >= 10) {
			headerElem.classList.add("sticky");
		} else {
			headerElem.classList.remove("sticky");
		}
	}

	window.addEventListener("scroll", stickyMenu);
	stickyMenu();
	// end sticky

	// simplebar
	if (document.querySelector(".header__simplebar") && innerWidth < 1024) {
		new SimpleBar(document.querySelector(".header__simplebar"));
	}
	// end simplebar

	//  swiper
	if (document.querySelector(".jobs__slider") && innerWidth < 1024) {
		MetaSwiper(`.jobs__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			pagination: {
				el: ".jobs__pagination",
				clickable: true,
			},
			breakpoints: {
				658: {
					spaceBetween: 25,
					slidesPerView: 2,
				},
			},
		});
	}
	if (document.querySelector(".advantages__slider") && innerWidth < 1024) {
		MetaSwiper(`.advantages__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			pagination: {
				el: ".advantages__pagination",
				clickable: true,
			},
			breakpoints: {
				658: {
					spaceBetween: 25,
					slidesPerView: 2,
				},
			},
		});
	}

	if (document.querySelector(".mission__slider")) {
		MetaSwiper(`.mission__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 15,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				551: {
					spaceBetween: 15,
					slidesPerView: 2,
				},
				1024: {
					spaceBetween: 20,
					slidesPerView: 3,
				}
			},
		});
	}

	if (document.querySelector(".reviews__slider")) {
		MetaSwiper(`.reviews__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				568: {
					spaceBetween: 20,
					slidesPerView: 2,
				}
			},
		});
	}

	if (document.querySelector(".stuff__slider")) {
		MetaSwiper(`.stuff__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 40,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				568: {
					spaceBetween: 20,
					slidesPerView: 2,
				},
				768: {
					spaceBetween: 20,
					slidesPerView: 3,
				},
				1023: {
					spaceBetween: 20,
					slidesPerView: 4,
				}
			},
		});
	}
	if (document.querySelector(".career__slider")) {
		MetaSwiper(`.career__slider`, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 20,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				658: {
					slidesPerView: 2,
				},
				1023: {
					slidesPerView: 3,
				}
			},
		});
	}

	if (document.querySelector(".team__slider")) {
		MetaSwiper(`.team__slider`, {
			loop: false,
			slidesPerView: 2,
			spaceBetween: 20,
			speed: 500,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			breakpoints: {
				551: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 5,
				},
				1366: {
					slidesPerView: 6,
				},
			},
		});
	}

	if (document.querySelector(".swiper.find__img-box")) {
		MetaSwiper(`.find__img-box`, {
			loop: false,
			slidesPerView: 1,
			effect: 'fade',
			speed: 400,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
		});
	}
	//  end swiper

	// double-range-slider
	const doubleRangeSliderTooltips = () => {
		const doubleRange = document.getElementsByClassName("find-job__range-box")[0];
		if (doubleRange) {
			const slider = doubleRange.querySelector("#double-range-tooltips");
			const max = +slider.dataset.max;
			const min = +slider.dataset.min;
			let unit = slider.dataset?.unit || "$";
			const step = +slider.dataset.step;
			const inputsHidden = doubleRange.querySelectorAll(".find-job__range-input");
			const startValueMin = +inputsHidden[0].value;
			const startValueMax = +inputsHidden[1].value;

			const currencyArr = {
				'gbp': '£',
				'usd': '$',
				'eur': '€',
				'aud': 'A$',
				'cad': 'C$',
				'jpy': '¥'
			}

			// how many percentages
			const borderLimit = 8;
			const borderDiff = 40 / borderLimit;

			noUiSlider.create(slider, {
				start: [startValueMin, startValueMax],
				connect: true,
				tooltips: true,
				margin: 10,
				step: step,
				range: {
					min: min,
					max: max,
				},
			});

			const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
			const circlesArr = slider.querySelectorAll(".noUi-origin");

			function returnTransform(element) {
				return element
					.replace(/[^0-9][^\d.]/g, "")
					.replace(")", "")
					.split(" ")
					.map((str) => {
						return Number(str);
					});
			}

			function needToMerge() {
				let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
				let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

				if (
					tooltipsArr[0].classList.contains("hidden") ||
					tooltipsArr[1].classList.contains("hidden")
				) {
					return true;
				}

				return (
					tooltipOnePosition.left +
					tooltipOnePosition.width -
					tooltipTwoPosition.left >
					0
				);
			}

			function resetTooltips(values) {
				mergeDiff = null;
				tooltipsArr.forEach((elem, index) => {
					elem.textContent =
						unit + Math.round(values[index]).toLocaleString("en-GB");
					elem.classList.remove("hidden");
				});
			}

			const locationSelect = document.querySelector('.js-currency')
			locationSelect.addEventListener('change', function() {
				let key = this.value
				for (const currency in currencyArr) {
					if (currency == key) {
						unit = currencyArr[currency]
					}
				}
				resetTooltips([+inputsHidden[0].value, +inputsHidden[1].value])
			})

			let trueTooltip = false;
			let mergeDiff = null;

			slider.noUiSlider.on("end", function () {
				const inputArr = document.querySelectorAll('.find-job__range-input')
				let minVal = 0;
				let maxVal = 0;
				inputArr.forEach((input, i) => {
					if (i) {
						maxVal = +inputArr[i].value
					} else {
						minVal = +inputArr[i].value
					}
				})
				changeSalary(minVal, maxVal);
			});

			slider.noUiSlider.on("update", function (values, handle) {
				let translate = returnTransform(circlesArr[handle].style.transform)[0];
				let valueMin = returnTransform(circlesArr[0].style.transform)[0];
				let valueMax = returnTransform(circlesArr[1].style.transform)[0];
				let difference = valueMax - valueMin;

				inputsHidden[handle].value = Math.round(values[handle]);

				if (translate <= -100 + borderLimit) {
					tooltipsArr[0].style.transform = `translate(${
						-60 + Math.abs(translate + 100 - borderLimit) * borderDiff
					}%,100%)`;
				} else if (translate >= -borderLimit) {
					tooltipsArr[1].style.transform = `translate(${
						-50 - Math.abs(translate + borderLimit) * borderDiff
					}%,100%)`;
				} else {
					tooltipsArr[handle].style.transform = "translate(-50%,100%)";
				}

				if (needToMerge()) {
					if (
						!tooltipsArr[+!handle].classList.contains("hidden") &&
						!tooltipsArr[handle].classList.contains("hidden")
					) {
						trueTooltip = handle;
						mergeDiff = difference;
						tooltipsArr[+!handle].classList.add("hidden");
					}

					if (trueTooltip) {
						tooltipsArr[trueTooltip].style.transform = `translate(${
							-10 - difference
						}%,100%)`;
					} else {
						tooltipsArr[trueTooltip].style.transform = `translate(${
							-95 + difference
						}%,100%)`;
					}

					tooltipsArr[trueTooltip].textContent = `${
						unit + Math.round(values[0]).toLocaleString("en-GB")
					} - ${unit + Math.round(values[1]).toLocaleString("en-GB")}`;

					if (mergeDiff && difference > mergeDiff) {
						mergeDiff = null;
						resetTooltips(values);

						if (trueTooltip) {
							tooltipsArr[0].style.transform = `translate(-20%, 100%)`;
						} else {
							tooltipsArr[1].style.transform = `translate(-90%, 100%)`;
						}
					}
				} else {
					resetTooltips(values);
				}
			});
		}
	};

	if (document.getElementsByClassName("find-job__range-box")[0]) {
		doubleRangeSliderTooltips();
	}

	// end double-range-slider

	const dynamicContent = document.querySelectorAll('dc')

	if (dynamicContent) {
		let words = []
		dynamicContent.forEach(el => {
			words = el.innerText.split(',').map(el => {
				return trim(el);
			})

			el.innerHTML = ''

			const typeInit = new TypeIt(el, {speed: 100, loop:true })

			words.forEach(word => {
				typeInit.type(word).pause(2000).delete()
			})

			typeInit.go();
		})
	}
});
